import React from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { InfinityIcon } from "./icons/InfinityIcon";

type InputProps = {
  label?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  help?: string;
  helpLink?: string;
  type?: string;
  numberStep?: string;
  error?: string;
  autoFocus?: boolean;
  inputSize?: "s" | "xs";
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  hasSteppers?: boolean;
  onStepUp?: () => void;
  onStepDown?: () => void;
  prefix?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
};

export const Input = (props: InputProps & WithMarginProp) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <Flex direction="column" margin={props.margin}>
      {props.label ? (
        <Text weight="semi" margin="0">
          {props.label}
        </Text>
      ) : null}
      {props.help && (
        <Text size="s" margin="0" colorPreset="secondary">
          {props.helpLink ? (
            <a href={props.helpLink} target="_blank" rel="noreferrer">
              <Text
                size="s"
                margin="0"
                colorPreset="secondary"
                isInline
                style={{ textDecoration: "underline" }}
              >
                Follow this guide{" "}
              </Text>
            </a>
          ) : null}
          {props.help}
        </Text>
      )}
      <InputWrapper>
        {props.prefix && (
          <InputPrefix inputSize={props.inputSize}>{props.prefix}</InputPrefix>
        )}
        <StyledInput
          ref={props.inputRef}
          type={props.type ? props.type : "text"}
          isError={!!props.error}
          placeholder={props.value === Infinity ? undefined : props.placeholder}
          value={props.value === Infinity ? "" : props.value}
          step={props.numberStep}
          name={props.name}
          autoFocus={props.autoFocus}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          hasLabel={props.label ? true : false}
          inputSize={props.inputSize}
          pattern={props.type === "phone" ? "[0-9]*" : undefined}
          disabled={props.isDisabled}
          onKeyDown={props.onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          prefix={props.prefix}
        />
        {props.value === Infinity && !isFocused && (
          <StyledInfinityIcon colorPreset="secondary" />
        )}
        {props.hasSteppers && (
          <StepperWrapper>
            <StepperButton onClick={props.onStepUp}>
              <span>+</span>
            </StepperButton>
            <StepperButton onClick={props.onStepDown}>
              <span>-</span>
            </StepperButton>
          </StepperWrapper>
        )}
      </InputWrapper>
      {props.error && (
        <Text size="xs" margin="s 0 0 0" color={theme.color.warning}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

interface StyledInputProps {
  inputSize?: "s" | "xs";
  hasPrefix?: boolean;
  isError?: boolean;
  hasLabel?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  width: 100%;

  ${(p) =>
    p.hasLabel
      ? css`
          margin-top: ${(p) => p.theme.spacing.s};
        `
      : null}

  ${(p) =>
    p.type === "date" || p.type === "number"
      ? css`
          width: 175px;
          @media (max-width: 500px) {
            width: 125px;
          }
        `
      : null}

  ${(p) =>
    p.type === "date"
      ? css`
          padding: calc(${(p) => p.theme.spacing.m} - 1px);
          ::-webkit-calendar-picker-indicator {
            margin-left: -10px;
          }
        `
      : null}

  border: 1.5px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: ${(p) => p.theme.typography.size.m};
  box-sizing: border-box;

  color: ${(p) => p.theme.color.typography.text};

  ${(p) =>
    p.inputSize === "xs"
      ? css`
          height: 36px;
          font-size: ${(p) => p.theme.typography.size.s};
        `
      : p.inputSize === "s"
      ? css`
          height: 42px;
          font-size: ${(p) => p.theme.typography.size.m};
        `
      : css`
          height: 48px;
          font-size: ${(p) => p.theme.typography.size.m};
        `}

  ${(p) =>
    p.prefix
      ? css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
      : css`
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
        `}

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
    font-weight: ${(p) => p.theme.typography.weight.normal};
  }

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.warning};
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.warning}20;
        `
      : null}

  ${(p) =>
    p.disabled
      ? css`
          background-color: ${(p) => p.theme.color.typography.secondary}10;
          border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
          cursor: not-allowed;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;

          &::selection {
            background: transparent;
          }

          &::-moz-selection {
            background: transparent;
          }

          &:hover {
            border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
            outline: none;
          }

          &:focus,
          &:active {
            box-shadow: none;
            outline: none;
          }
        `
      : null}
`;

interface FakeInputProps {
  isActive?: boolean;
}

export const FakeInput = styled.div<FakeInputProps>`
  background-color: ${(p) => p.theme.color.card.background};

  border: 1.5px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: ${(p) => p.theme.typography.size.m};
  max-height: 38px;
  width: 100%;

  color: ${(p) => p.theme.color.typography.text};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  ${(p) =>
    p.isActive
      ? css`
          border-color: ${(p) => p.theme.color.primary} !important;
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
          outline: none;
        `
      : null}

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

export const DoubleInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.l};
  margin-top: ${(p) => p.theme.spacing.s};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInfinityIcon = styled(InfinityIcon)`
  position: absolute;
  left: ${(p) => p.theme.spacing.m};
  pointer-events: none;
  opacity: 0.5;
`;

const StepperWrapper = styled.div`
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${(p) => p.theme.color.typography.secondary}50;
`;

const StepperButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${(p) => p.theme.spacing.xs};
  font-size: ${(p) => p.theme.typography.size.m};
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}20;
  }

  &:first-child {
    border-bottom: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  }
`;

const InputPrefix = styled.div<{ inputSize?: "xs" | "s" }>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.color.card.callout};
  border-top-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border-bottom-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.typography.size.l} !important;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  width: 52px;
  box-sizing: border-box;

  ${(p) =>
    p.inputSize === "xs"
      ? css`
          height: 36px;
          font-size: ${(p) => p.theme.typography.size.s};
        `
      : p.inputSize === "s"
      ? css`
          height: 42px;
          font-size: ${(p) => p.theme.typography.size.m};
        `
      : css`
          height: 52px;
          font-size: ${(p) => p.theme.typography.size.m};
        `}
`;

import { add, fromUnixTime, isPast } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { toast } from "sonner";
import { Avatar } from "../components/Avatar";
import { Button, CTA, StyledButtonWrap } from "../components/CTA";
import { Flex } from "../components/Flex";
import { H2 } from "../components/Heading";
import Loading from "../components/Loading";
import { Modal } from "../components/Modal";
import { Page } from "../components/Page";
import ProtectedRoute from "../components/ProtectedRoute";
import { Text } from "../components/Text";
import { TikTokIcon } from "../components/icons/TikTokIcon";
import { MOBILE_BREAKPOINT } from "../config";
import {
  BillingBannerQuery,
  BillingStatus,
  useBillingBannerQuery,
  useConnectToTikTokMutation,
  useGetCreatorQuery,
  useResendEmailVerificationMutation,
} from "../graphql/generated";
import useAnalytics from "../hooks/useAnalytics";
import useGqlClient from "../hooks/useGqlClient";
import { useTheme } from "../hooks/useTheme";
import { useWindowSize } from "../hooks/useWindowSize";
import { authSelectors } from "../store/auth/selector";
import { actions } from "../store/auth/slice";
import styled from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import withPadding, { WithPaddingProp } from "../styles/withPadding";
import { ConfirmDetails } from "./ConfirmDetails";
import { Signup } from "./Signup";
import { BrandRoot, PageContent } from "./brands";
import { EmailVerified } from "./brands/EmailVerified";
import { ForgotPassword } from "./brands/ForgotPassword";
import { Invite } from "./brands/Invite";

import { ResetPassword } from "./brands/ResetPassword";
import { SignupRoot } from "./brands/signup";
import { InfluencerRoot } from "./influencers";
import { FacebookRedirect } from "./influencers/tokened/FacebookRedirect";
import { InstagramRedirect } from "./influencers/tokened/InstagramRedirect";
import { TikTokRedirect } from "./influencers/tokened/TikTokRedirect";
import { BookingResponse } from "./tokened/bookingResponse";
import { BookingResponseConfirmed } from "./tokened/bookingResponseConfirmed";
import { BrandLogin } from "./unauthenticated/brands/login";
import { ListingInvites } from "./unauthenticated/influencers/ListingInvites";
import { ManageBooking } from "./unauthenticated/influencers/ManageBooking";
import { Login } from "./unauthenticated/influencers/login";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BannerWrap = styled.div<{ backgroundColor?: string } & WithPaddingProp>`
  width: 100%;
  background: ${(p) =>
    p.backgroundColor ? p.backgroundColor : p.theme.color.warning};
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  box-sizing: border-box;
  z-index: 4;

  .margin-left-for-nav {
    margin-left: 252px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .margin-left-for-nav {
      margin-left: 0px;
    }
  }

  ${withPadding};
`;

const VerifyEmailBtn = styled.button<WithMarginProp>`
  line-height: 100%;
  text-decoration: none;
  color: white;
  background: none;
  border: none;
  font-size: ${(p) => p.theme.typography.size.m};

  :hover {
    filter: brightness(90%);
    cursor: pointer;
  }

  span {
    margin-left: ${(p) => p.theme.spacing.s};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    border-bottom: 2px solid #fff;
    padding-bottom: 2px;
    font-size: ${(p) => p.theme.typography.size.m};
  }

  ${withMargin};
`;

const BillingLink = styled(Link)<WithMarginProp>`
  line-height: 100%;
  text-decoration: none;
  color: white;

  :hover {
    filter: brightness(90%);
  }

  span {
    margin-left: ${(p) => p.theme.spacing.xs};
    font-weight: ${(p) => p.theme.typography.weight.semi};
  }

  ${withMargin}
`;

const Routes = () => {
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const dispatch = useDispatch();

  const client = useGqlClient();
  const { data } = useBillingBannerQuery(client, {
    brandID: activeBrandId ? activeBrandId : "",
  });

  const query = useQuery();
  const [ttCode, setTtCode] = useState("");

  useEffect(() => {
    const ttCode = query.get("code");
    if (!ttCode) {
      return;
    }

    const fromSettings = query.get("fromSettings");
    const csrf = query.get("state");
    if ((csrf && !csrf.endsWith("tiktok")) || !fromSettings) {
      return;
    }

    setTtCode(ttCode);
    setShowTikTok(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.toString()]);

  useEffect(() => {
    if (!data || !data.brand || !data.brand.billingStatus) {
      return;
    }

    dispatch(actions.setBrandBillingStatus(data.brand.billingStatus));
  }, [data, dispatch]);

  const [showTikTok, setShowTikTok] = useState(false);

  return (
    <>
      <Banner query={data} />

      <Switch>
        <Route path="/forgot-password">
          <PageContent noNav>
            <ForgotPassword />
          </PageContent>
        </Route>

        <Route path="/reset-password/:token">
          <PageContent noNav>
            <ResetPassword />
          </PageContent>
        </Route>

        <ProtectedRoute path="/verify-email/:accountId/:token">
          <PageContent noNav>
            <EmailVerified />
          </PageContent>
        </ProtectedRoute>

        <ProtectedRoute path="/confirm-details">
          <PageContent noNav>
            <ConfirmDetails />
          </PageContent>
        </ProtectedRoute>

        <Route path="/brand-invites">
          <PageContent noNav>
            <Invite />
          </PageContent>
        </Route>

        <Route path="/listing-invites/:listingId">
          <PageContent noNav>
            <ListingInvites />
          </PageContent>
        </Route>

        <Route path="/listing-invites/">
          <PageContent noNav>
            <ListingInvites />
          </PageContent>
        </Route>

        <Route path="/manage-booking/:bookingId">
          <PageContent noNav>
            <ManageBooking />
          </PageContent>
        </Route>

        <Route path="/i/login">
          <PageContent>
            <Login mode="login" />
          </PageContent>
        </Route>
        <Route path="/i/signup">
          <PageContent>
            <Login mode="signup" />
          </PageContent>
        </Route>

        <Route path="/login">
          <PageContent noNav>
            <Signup />
          </PageContent>
        </Route>

        <Route path="/signup">
          <PageContent noNav>
            <SignupRoot />
          </PageContent>
        </Route>

        <Route path="/b/login">
          <PageContent noNav>
            <BrandLogin mode="login" />
          </PageContent>
        </Route>
        <ProtectedRoute path="/i">
          <PageContent>
            <InfluencerRoot />
          </PageContent>
        </ProtectedRoute>

        <ProtectedRoute path="/b">
          <BrandRoot />
        </ProtectedRoute>

        <Route exact path="/">
          {activeBrandId ? (
            <Redirect to="/b" />
          ) : (
            <Redirect to="/signup/b/create-brand" />
          )}
        </Route>
        <Route exact path="/bookingApproval/:id/:token">
          <Page>
            <PageContent>
              <BookingResponse />
            </PageContent>
          </Page>
        </Route>
        <Route exact path="/bookingApproval/done">
          <Page>
            <PageContent>
              <BookingResponseConfirmed />
            </PageContent>
          </Page>
        </Route>

        <Route
          exact
          path="/app-connect-tiktok/redirect/:accessToken/:creatorId"
        >
          <Page>
            <PageContent>
              <TikTokRedirect />
            </PageContent>
          </Page>
        </Route>

        <Route exact path="/app-connect-tiktok/connect">
          <Page>
            <PageContent>
              <Loading />
            </PageContent>
          </Page>
        </Route>

        <Route
          exact
          path="/app-connect-facebook/redirect/:accessToken/:creatorId"
        >
          <Page>
            <PageContent>
              <FacebookRedirect />
            </PageContent>
          </Page>
        </Route>

        <Route exact path="/app-connect-facebook/connect">
          <Page>
            <PageContent>
              <Loading />
            </PageContent>
          </Page>
        </Route>

        <Route
          exact
          path="/app-connect-instagram/redirect/:accessToken/:creatorId"
        >
          <Page>
            <PageContent>
              <InstagramRedirect />
            </PageContent>
          </Page>
        </Route>

        <Route exact path="/app-connect-instagram/connect">
          <Page>
            <PageContent>
              <Loading />
            </PageContent>
          </Page>
        </Route>
      </Switch>
      <RouteAnalytics />
      <Modal isOpen={showTikTok} setIsOpen={setShowTikTok}>
        <ConnectToTikTok
          code={ttCode}
          onClose={() => {
            setShowTikTok(false);
          }}
        />
      </Modal>
    </>
  );
};

interface ConnectToTikTokProps {
  code: string;
  onClose: () => void;
}

const ConnectToTikTok = (props: ConnectToTikTokProps) => {
  const client = useGqlClient();
  const connectToTikTok = useConnectToTikTokMutation(client);
  const activeCreatorId = useSelector(authSelectors.activeCreatorId);

  const { isLoading, data, error, refetch } = useGetCreatorQuery(
    client,
    {
      id: activeCreatorId ? activeCreatorId : "",
    },
    {
      enabled: activeCreatorId !== "",
    }
  );

  useEffect(() => {
    connectToTikTok.mutate(
      {
        ttCode: props.code,
        redirectUri: encodeURIComponent(window.location.href),
      },
      {
        onSuccess: () => {
          toast.success("TikTok connected");
          refetch();
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  if (connectToTikTok.isLoading || isLoading || !data) {
    return <Loading />;
  }

  if (connectToTikTok.error || error || !data.creator.tikTok) {
    return (
      <Flex direction="column">
        <Text weight="bold">Failed to connect to TikTok</Text>
        <Text>Please try again</Text>
        <CTA to="#" onClick={() => props.onClose()}>
          Ok
        </CTA>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex align="center">
        <TikTokIcon width={20} height={20} />
        <Text weight="bold">Connected to TikTok!</Text>
      </Flex>
      <Avatar margin="0 l 0 0" size={50} url={data.creator.tikTok.avatar} />
      <Text>{data.creator.tikTok.displayName}</Text>
      <CTA
        to="#"
        onClick={() => {
          props.onClose();
        }}
      >
        Continue
      </CTA>
    </Flex>
  );
};

interface BannerProps {
  query: BillingBannerQuery | undefined;
}

function Banner(props: BannerProps) {
  const account = useSelector(authSelectors.account);
  const location = useLocation();
  const { showNewMessages } = useIntercom();
  const client = useGqlClient();
  const resend = useResendEmailVerificationMutation(client);
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const window = useWindowSize();
  const isMobile = window.width && window.width < MOBILE_BREAKPOINT;

  useEffect(() => {
    if (
      props.query?.brand?.billingStatus === BillingStatus.BillingStatusCancelled
    ) {
      setShowCancelModal(true);
    }
  }, [props.query]);

  useEffect(() => {
    if (!account) {
      return;
    }

    const accountCreationPlus24Hours = add(fromUnixTime(account.createdAt), {
      hours: 24,
    });

    const hasBeen24HoursSinceAccountCreation = isPast(
      accountCreationPlus24Hours
    );

    if (
      account &&
      !account.emailVerifiedAt &&
      !location.pathname.includes("verify-email")
    ) {
      hasBeen24HoursSinceAccountCreation
        ? setShowModal(true)
        : setShowBanner(true);
    }
  }, [account, location.pathname]);

  if (!account || !account.createdAt || !isLoggedIn) {
    return null;
  }

  // escalate email verification banner after 24 hours
  if (showModal) {
    return (
      <div>
        <Modal isOpen={showModal} maxWidth={500}>
          <Flex align="center" direction="column" justify="center">
            <H2 margin={"xs 0 0"}>Confirm email</H2>
            <Text size="m" margin={"xl 0"} align="center">
              Please confirm your email address to continue using Joli
            </Text>

            <CTA
              to="#"
              margin="0 0 m"
              style={
                emailSent ? { background: theme.color.constructive } : undefined
              }
              onClick={() => {
                if (resend.isSuccess) {
                  return;
                }

                resend.mutate(
                  {},
                  {
                    onSuccess: () => {
                      setEmailSent(true);
                      setTimeout(() => {
                        setShowModal(false);
                      }, 1000);
                    },
                  }
                );
              }}
            >
              {emailSent ? "Email sent" : "Resend email"}
            </CTA>
            {emailSent ? null : (
              <CTA
                style={{ margin: "0 auto" }}
                type="quaternary"
                to="#"
                onClick={() => setShowModal(false)}
              >
                Not now
              </CTA>
            )}
          </Flex>
        </Modal>
      </div>
    );
  }

  if (
    showBanner &&
    !isMobile &&
    !location.pathname.includes("verify-email") &&
    !location.pathname.includes("signup") &&
    !location.pathname.includes("confirm-details") &&
    !location.pathname.includes("login") &&
    !location.pathname.includes("listings/lst") &&
    !location.pathname.includes("create-listing")
  ) {
    return (
      <BannerWrap padding="l 0" backgroundColor={theme.color.primary}>
        <VerifyEmailBtn
          className="margin-left-for-nav"
          onClick={() => {
            if (resend.isSuccess) {
              return;
            }

            resend.mutate({});
          }}
        >
          Please confirm your email address: {account.email}
          <span>
            {resend.isLoading
              ? "Sending..."
              : resend.isSuccess
              ? "Sent! Check your inbox"
              : "Resend email"}
          </span>
        </VerifyEmailBtn>
      </BannerWrap>
    );
  }

  if (!props.query || !props.query.brand) {
    return null;
  }

  if (
    props.query.brand.billingStatus ===
      BillingStatus.BillingStatusFreePlanExpired &&
    !location.pathname.endsWith("billing") &&
    !location.pathname.includes("listings/lst") &&
    !location.pathname.includes("create-listing")
  ) {
    return (
      <BannerWrap padding="l 0">
        <BillingLink to="/b/billing" className="margin-left-for-nav">
          Your free trial has expired.
          <span>Activate now →</span>
        </BillingLink>
      </BannerWrap>
    );
  }

  if (
    props.query.brand.billingStatus ===
      BillingStatus.BillingStatusNeedsPayment &&
    !location.pathname.endsWith("billing") &&
    !location.pathname.includes("listings/lst") &&
    !location.pathname.includes("create-listing")
  ) {
    return (
      <BannerWrap padding="l 0">
        <BillingLink
          className="margin-left-for-nav"
          to="/b/billing"
          onClick={() => {
            showNewMessages("Hey Joli Team, can you help with my billing?");
          }}
        >
          Your payment has failed.
          <span>Chat to us →</span>
        </BillingLink>
      </BannerWrap>
    );
  }

  if (showCancelModal && !location.pathname.endsWith("billing")) {
    return (
      <Modal isOpen={showCancelModal} maxWidth={580}>
        <H2 margin="0 0 l">Subscription Cancelled</H2>
        <Text size="l" margin="0 0 xl">
          Your subscription has been cancelled. If you'd like to reactivate it,
          please get in touch with the Joli team.
        </Text>

        <StyledButtonWrap margin="xl 0 0">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowCancelModal(false);
            }}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              showNewMessages(
                "Hey Joli Team, can you help me reactivate my subscription?"
              );
            }}
          >
            Chat to us
          </Button>
        </StyledButtonWrap>
      </Modal>
    );
  }

  return null;
}

const RouteAnalytics = () => {
  const location = useLocation();
  const { track } = useAnalytics();

  useEffect(() => {
    track("Pageview", { path: location.pathname });
  }, [location, track]);

  return null;
};

export default Routes;

import { useEffect } from "react";
import Loading from "../../../components/Loading";
import config from "../../../config";

export function InstagramRedirect() {
  const stateHash = `${(+new Date().setHours(0, 0, 0, 0)).toString(
    36
  )}_instagram`;

  useEffect(() => {
    const redirectUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=${
      config.facebookAppId
    }&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/app-connect-instagram/connect`
    )}&response_type=code&scope=instagram_business_basic,instagram_business_manage_insights&state=${stateHash}`;

    console.log("Redirecting to:", redirectUrl);

    window.location.href = redirectUrl;
  }, [stateHash]);

  return <Loading />;
}

import { useState } from "react";
import styled from "../styles";
import { Button } from "./CTA";
import { Flex } from "./Flex";

const ShareLinkWrap = styled(Flex)`
  background: ${(p) => p.theme.color.typography.secondary}16;
  border-radius: 8px;
  outline: none;
  border: none;
  position: relative;
`;

const ShareLinkInput = styled.input`
  border: none;
  color: ${(p) => p.theme.color.typography.text};
  background-color: transparent;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin-right: 10px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  border: none;
`;

export const ShareLink = ({ link }: { link: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <ShareLinkWrap>
      <ShareLinkInput autoFocus={false} type="text" value={link} />
      <ButtonWrap>
        <ShareLinkButton
          isCopied={isCopied}
          size="xs"
          onClick={() => {
            navigator.clipboard.writeText(link);
            setIsCopied(true);
          }}
        >
          {isCopied ? "Copied!" : "Copy"}
        </ShareLinkButton>
      </ButtonWrap>
    </ShareLinkWrap>
  );
};

const ButtonWrap = styled(Flex)`
  margin: 8px;
`;

const ShareLinkButton = styled(Button)<{ isCopied: boolean }>`
  border-radius: 8px;
  width: 32px;
  background-color: ${(p) =>
    p.isCopied ? p.theme.color.constructive : p.theme.color.primary};
`;

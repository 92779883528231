import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "sonner";
import { Avatar } from "../../components/Avatar";
import { UpgradePrompt } from "../../components/Brand/UpgradePrompt";
import { Button } from "../../components/CTA";
import { Card } from "../../components/Card";
import { CardLink, ExternalCardLink } from "../../components/CardLink";
import { Chevron } from "../../components/Chevron";
import { Flex } from "../../components/Flex";
import { H1, H2, H3 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { Text } from "../../components/Text";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { InstagramIcon } from "../../components/icons/InstagramIcon";
import { TikTokIcon } from "../../components/icons/TikTokIcon";
import { WarningIcon } from "../../components/icons/WarningIcon";
import config, { MOBILE_BREAKPOINT } from "../../config";
import {
  BillingPlanType,
  useConnectToInstagramMutation,
  useConnectToTikTokMutation,
  useCreateToggleUserMutation,
  useGetBrandQuery,
  useUpdateBrandMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";
import withMargin from "../../styles/withMargin";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 500px;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

const DataSourceWrap = styled(Card)`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l};
`;

const AvatarWrap = styled.div`
  position: relative;
  margin-top: -3px;
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  position: absolute;
  background-color: ${(p) => p.theme.color.card.background};
  padding: 1px;
  right: -2px;
  bottom: -2px;
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

const StyledTikTokIcon = styled(TikTokIcon)`
  position: absolute;
  background-color: ${(p) => p.theme.color.card.background};
  padding: 1px;
  right: -2px;
  bottom: -2px;
  border-radius: 4px;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Username = styled.p<{ margin?: string }>`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.s};
  line-height: 1.3em;
  margin: 0;
  ${withMargin};
`;

const TikTokWrap = styled(Flex)`
  width: 40px;
  padding-left: 2px;
`;

const InstagramWrap = styled(Flex)`
  width: 40px;
`;

const ToggleIcon = styled.img`
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

export interface MatchParams {
  page: string;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface OAuthResponse {
  code: string | null;
  error: string | null;
  state: string | null;
}

export const BrandSettings = () => {
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const query = useQuery();
  const client = useGqlClient();
  const personalAccount = useSelector(authSelectors.account);
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const updateBrand = useUpdateBrandMutation(client);

  const brandQuery = useGetBrandQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  const connectToInstagramMutation = useConnectToInstagramMutation(client);
  const connectToTikTokMutation = useConnectToTikTokMutation(client);
  const createToggleUserMutation = useCreateToggleUserMutation(client);

  const stateHash = `${(+new Date().setHours(0, 0, 0, 0)).toString(
    36
  )}_instagram`;

  const [fbError, setFbError] = useState("");
  const [ttError, setTtError] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggleConnectionError, setToggleConnectionError] = useState(false);

  const [toggleUsername, setToggleUsername] = useState("");
  const [togglePassword, setTogglePassword] = useState("");
  const [toggleError, setToggleError] = useState("");
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [showDmnModal, setShowDmnModal] = useState(false);

  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [dmnEnabled, setDmnEnabled] = useState(false);

  function handleToggleModalClose() {
    if (createToggleUserMutation.isLoading) {
      return;
    }
    setShowToggleModal(false);
    setToggleError("");
  }

  useEffect(() => {
    const handleOAuthFlow = ({ code, error, state }: OAuthResponse) => {
      if (!code) return;

      const cleanupUrl = () => {
        const url = new URL(window.location.href);
        ["code", "error", "error_reason", "error_description", "state"].forEach(
          (param) => {
            url.searchParams.delete(param);
          }
        );
        window.history.replaceState({}, "", url.toString());
      };

      // Instagram Flow
      if (state === stateHash) {
        connectToInstagramMutation.mutate(
          {
            code,
            redirectUri: `${window.location.origin}/b/settings`,
          },
          {
            onSuccess: () => {
              toast.success("Instagram connected");
              track("Instagram connected");
              brandQuery.refetch();
              cleanupUrl();
            },
            onError: (error) => {
              Sentry.captureException(error);
              setFbError("Failed to connect with Instagram");
              cleanupUrl();
            },
          }
        );
        return;
      }

      // TikTok Flow
      const csrf = query.get("state");
      if (csrf && !csrf.endsWith("tiktok")) {
        return;
      }

      connectToTikTokMutation.mutate(
        {
          ttCode: code,
          redirectUri: `${window.location.origin}/b/settings/`,
        },
        {
          onSuccess: () => {
            toast.success("TikTok connected");
            track("TikTok connected");
            brandQuery.refetch();
            cleanupUrl();
          },
          onError: (error) => {
            Sentry.captureException(error);
            setTtError("Failed to login with TikTok");
            cleanupUrl();
          },
        }
      );
    };

    const params: OAuthResponse = {
      code: query.get("code"),
      error: query.get("error"),
      state: query.get("state"),
    };

    if (params.error) {
      const service = params.state === stateHash ? "Instagram" : "TikTok";
      const errorMessage = `Failed to connect with ${service}`;
      params.state === stateHash
        ? setFbError(errorMessage)
        : setTtError(errorMessage);
      Sentry.captureException(params.error);
      return;
    }

    handleOAuthFlow(params);
  }, [query]);

  useEffect(() => {
    if (!brandQuery.data || !brandQuery.data.brand) {
      return;
    }

    const data = brandQuery.data;

    setDmnEnabled(brandQuery.data.brand.dmnEnabled);

    const currentPlan = data.billingPlans.find(
      (bp) => bp.id === data.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt =
      currentPlan &&
      currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
        ? true
        : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);
  }, [brandQuery.data]);

  if (brandQuery.isLoading || !brandQuery.data || !personalAccount) {
    return <Loading />;
  }

  if (brandQuery.error || !brandQuery.data.brand) {
    return (
      <Wrap>
        <H1 margin="xl 0">Settings</H1>
        <Text>Something went wrong</Text>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <H1 margin="0 0 xl">Integrations</H1>
      <H3 margin="m 0 0">Social profiles</H3>
      <Text colorPreset="secondary" margin="xs 0 0 0">
        Unlock analytics from influencer content
      </Text>

      {brandQuery.data.brand.instagram ? (
        brandQuery.data.brand.igConnectionExpired ? (
          <ExternalCardLink
            href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=${config.instagramAppId}&redirect_uri=${window.location.origin}/b/settings&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_insights&state=${stateHash}`}
          >
            <DataSourceWrap hover margin="m 0 0">
              <Flex justify="space-between" align="center">
                <Flex direction="row" align="center">
                  <AvatarWrap>
                    <Avatar
                      style={{ position: "relative" }}
                      url={brandQuery.data!.brand!.instagram!.avatar}
                    />
                    <StyledInstagramIcon width={14} />
                  </AvatarWrap>

                  <Flex direction="column" margin="0 0 0 m">
                    <Title>{brandQuery.data!.brand!.instagram!.name}</Title>
                    <Flex align="center">
                      <Username margin="0">Connection Expired</Username>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex>
                  <Button buttonType="tertiary">Reconnect</Button>
                </Flex>
              </Flex>
            </DataSourceWrap>
          </ExternalCardLink>
        ) : (
          <DataSourceWrap margin="m 0 0">
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <AvatarWrap>
                  <Avatar
                    style={{ position: "relative" }}
                    url={brandQuery.data.brand.instagram.avatar}
                  />
                  <StyledInstagramIcon width={14} />
                </AvatarWrap>
                <Flex direction="column" margin="0 0 0 m">
                  <Title>{brandQuery.data.brand.instagram.name}</Title>
                  <Flex align="center">
                    <Username margin="0">Connected</Username>
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <CheckCircle checked />
              </Flex>
            </Flex>
          </DataSourceWrap>
        )
      ) : (
        <ExternalCardLink
          href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=${config.instagramAppId}&redirect_uri=${window.location.origin}/b/settings&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_insights&state=${stateHash}`}
        >
          <DataSourceWrap hover margin="m 0 0">
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <InstagramWrap align="center" justify="center">
                  <InstagramIcon width={34} />
                </InstagramWrap>

                <Flex direction="column" margin="0 0 0 m">
                  <Title>Connect Instagram</Title>
                </Flex>
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </DataSourceWrap>
        </ExternalCardLink>
      )}

      {fbError ? (
        <Text size="s" margin="s 0 0 0" colorPreset="warning">
          {fbError}
        </Text>
      ) : null}

      {brandQuery.data.brand.ttReportingEnabled &&
      brandQuery.data.brand.tikTok ? (
        <DataSourceWrap margin="m 0 m">
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <AvatarWrap>
                <Avatar
                  style={{ position: "relative" }}
                  url={brandQuery.data.brand.tikTok.avatar}
                />
                <StyledTikTokIcon width={14} />
              </AvatarWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>{brandQuery.data.brand.tikTok.displayName}</Title>
                <Flex align="center">
                  <Username margin="0">Connected</Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <CheckCircle checked />
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : (
        <>
          <ExternalCardLink
            href={`${
              config.apiEndpoint
            }/oauth/tiktok?redirect_uri=${encodeURIComponent(
              `${window.location.href}`
            )}&scope=user.info.basic,user.info.profile,user.info.stats,video.list`}
          >
            <DataSourceWrap hover margin="m 0 m">
              <Flex justify="space-between" align="center">
                <Flex direction="row" align="center">
                  <TikTokWrap align="center" justify="center">
                    <TikTokIcon width={36} />
                  </TikTokWrap>
                  <Flex direction="column" margin="0 0 0 m">
                    <Title>Connect Tik Tok</Title>
                  </Flex>
                </Flex>
                <Flex>
                  <Chevron />
                </Flex>
              </Flex>
            </DataSourceWrap>
          </ExternalCardLink>
        </>
      )}

      {ttError ? (
        <Text size="s" margin="0 0 0 m" colorPreset="warning">
          {ttError}
        </Text>
      ) : null}

      <H3 margin="xl 0 0">Integrations</H3>
      <Text colorPreset="secondary" margin="xs 0 0 0">
        Connect Joli to other tools your teams use
      </Text>
      <Modal
        isOpen={showToggleModal}
        setIsOpen={handleToggleModalClose}
        maxWidth={500}
      >
        <H2 margin="0 0 l">Log in to Toggle</H2>
        <Text margin="0 0 0">
          By connecting Toggle, we'll create and share gift cards for confirmed
          Joli bookings.{<br />}
          {<br />}Once connected, you'll be able to assign Toggle products and
          values when creating a listing.
          {<br />}
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://academy.usetoggle.com/en/articles/6428926-how-to-connect-nibble-app-and-toggle"
        >
          <Text
            size="s"
            margin="l 0 l"
            colorPreset="secondary"
            isInline
            style={{ textDecoration: "underline" }}
          >
            How do I connect Joli and Toggle?
          </Text>
        </a>
        <Input
          name="username"
          placeholder="Username"
          // label="Username"
          onChange={(e) => setToggleUsername(e.currentTarget.value)}
          margin="0 0 m 0"
        />
        <Input
          name="password"
          type="password"
          placeholder="Password"
          onChange={(e) => setTogglePassword(e.currentTarget.value)}
          margin="0 0 m 0"
        />
        {toggleError ? (
          <Text margin="0 0 m" colorPreset="warning">
            {toggleError}
          </Text>
        ) : null}
        <Button
          onClick={() => {
            if (createToggleUserMutation.isLoading) {
              return;
            }

            createToggleUserMutation.mutate(
              {
                username: toggleUsername,
                password: togglePassword,
              },
              {
                onSuccess: () => {
                  toast.success("Toggle connected");
                  track("Toggle connected");
                  setShowToggleModal(false);
                  brandQuery.refetch();
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setToggleError("Failed to connect to Toggle");
                },
              }
            );
          }}
        >
          {createToggleUserMutation.isLoading ? "Loading..." : "Login"}
        </Button>
      </Modal>
      <Modal isOpen={showDmnModal} setIsOpen={setShowDmnModal} maxWidth={500}>
        <H2 margin="0 0 l">Connect Access Collins</H2>
        <Text margin="0 0 0">
          By connecting Access Collins, we'll only show dates and times that are
          available for influencers to apply to.{<br />}
          {<br />}Once connected, you'll need to add your Collins site ID's to
          your Joli locations.
          {<br />}
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://accessgroup.my.site.com/Support/s/article/Access-Collins-Where-can-I-find-the-site-ID"
        >
          <Text
            size="s"
            margin="l 0 l"
            colorPreset="secondary"
            isInline
            style={{ textDecoration: "underline" }}
          >
            How do I find my Access Collins site ID's?
          </Text>
        </a>
        <Button
          onClick={() => {
            updateBrand.mutate(
              {
                input: {
                  id: activeBrandId ? activeBrandId : "",
                  dmnEnabled: true,
                },
              },
              {
                onSuccess: () => {
                  toast.success("Access Collins connected");
                  track("Access Collins connected");
                  setDmnEnabled(true);
                  setShowDmnModal(false);
                  queryClient.resetQueries(["BrandLocations"], {
                    exact: false,
                  });
                },
              }
            );
          }}
        >
          Connect
        </Button>
      </Modal>

      {brandQuery.data.brand.toggleIntegrationEnabled ? (
        <DataSourceWrap margin="m 0 0">
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Toggle</Title>
                <Flex align="center">
                  <Username margin="0">
                    {toggleConnectionError ? `Reconnect Toggle` : `Connected`}
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              {toggleConnectionError ? (
                <WarningIcon />
              ) : (
                <CheckCircle checked />
              )}
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : showUpgradePrompt ? (
        <DataSourceWrap margin="m 0 0" style={{ overflow: "visible" }}>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Toggle</Title>
                <Username margin="0">
                  Generate and share influencer gift cards
                </Username>
              </Flex>
            </Flex>
            <Flex>
              <UpgradePrompt feature="toggle" />
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : (
        <CardLink to="#" onClick={() => setShowToggleModal(!showToggleModal)}>
          <DataSourceWrap hover margin="m 0 0">
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <TikTokWrap align="center" justify="center">
                  <ToggleIcon
                    width={40}
                    src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                  />
                </TikTokWrap>
                <Flex direction="column" margin="0 0 0 m">
                  <Title>Connect Toggle</Title>
                  <Username margin="0">
                    Create gift cards for Joli bookings
                  </Username>
                </Flex>
                {toggleConnectionError ? (
                  <Text colorPreset="warning">{toggleConnectionError}</Text>
                ) : null}
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </DataSourceWrap>
        </CardLink>
      )}

      {dmnEnabled ? (
        <DataSourceWrap margin="m 0 0">
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Access Collins</Title>
                <Flex align="center">
                  <Username margin="0">Connected</Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <CheckCircle checked />
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : showUpgradePrompt ? (
        <DataSourceWrap margin="m 0 0" style={{ overflow: "visible" }}>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Access Collins</Title>
                <Flex align="center">
                  <Username margin="0">
                    Show real time booking availability
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <UpgradePrompt feature="dmn" />
          </Flex>
        </DataSourceWrap>
      ) : (
        <CardLink to="#" onClick={() => setShowDmnModal(!showDmnModal)}>
          <DataSourceWrap hover margin="m 0 0">
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <TikTokWrap align="center" justify="center">
                  <ToggleIcon
                    width={40}
                    src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                  />
                </TikTokWrap>
                <Flex direction="column" margin="0 0 0 m">
                  <Title>Connect Access Collins</Title>
                  <Flex align="center">
                    <Username margin="0">
                      Show real time booking availability
                    </Username>
                  </Flex>
                </Flex>
              </Flex>
              <Chevron />
            </Flex>
          </DataSourceWrap>
        </CardLink>
      )}
    </Wrap>
  );
};

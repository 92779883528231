import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  FileUploaderRegular,
  FuncFileValidator,
  OutputCollectionState,
  OutputCollectionStatus,
  UploadCtxProvider,
} from "@uploadcare/react-uploader";
import "@uploadcare/react-uploader/core.css";
import { useEffect, useRef, useState } from "react";
import { ListingCard } from ".";
import { SingleCheckBox } from "../../../components/Checkbox";
import { CloseIcon } from "../../../components/CloseIcon";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H4 } from "../../../components/Heading";
import { PicturesIcon } from "../../../components/icons/PictureIcon";
import { PlayIcon } from "../../../components/icons/PlayIcon";
import { VideoIcon } from "../../../components/icons/VideoIcon";
import { Input } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { Underline } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { TextArea } from "../../../components/TextArea";
import { ToggleButton } from "../../../components/ToggleButton";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  Listing,
  useCategoriesQuery,
  useDietaryPreferencesQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";

const ageRestrictions = [
  {
    value: 18,
    label: "18+",
  },
  {
    value: 21,
    label: "21+",
  },
  {
    value: 25,
    label: "25+",
  },
];

interface ListingDetailsProps {
  name: string;
  setName: (newName: string) => void;
  nameError?: string;
  details: string;
  setDetails: (newName: string) => void;
  detailsError?: string;
  dietaryPreferences: Listing["dietaryPreferences"];
  setDietaryPreferences: React.Dispatch<
    React.SetStateAction<Listing["dietaryPreferences"]>
  >;
  images: string[];
  setImages: (newImages: string[]) => void;
  imagesError?: string;
  phoneRequired: boolean;
  setPhoneRequired: React.Dispatch<React.SetStateAction<boolean>>;
  ageRestrictions: number | null;
  setAgeRestrictions: React.Dispatch<React.SetStateAction<number | null>>;
  categories: string[];
  setCategories: (newCategories: string[]) => void;
  videoUrl: string;
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
  nameRef?: React.RefObject<HTMLInputElement>;
  detailsRef?: React.RefObject<HTMLTextAreaElement>;
  imagesRef?: React.RefObject<HTMLDivElement>;
  categoriesRef?: React.RefObject<HTMLDivElement>;
  categoriesError?: string;
}

const ListingDetails = (props: ListingDetailsProps) => {
  const theme = useTheme();
  const [enableAgeRestrictions, setEnableAgeRestrictions] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playVideo, setPlayVideo] = useState<boolean | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const client = useGqlClient();

  const uploaderRef = useRef<InstanceType<UploadCtxProvider> | null>(null);
  const videoUploaderRef = useRef<InstanceType<UploadCtxProvider> | null>(null);

  const isVideoValidator: FuncFileValidator = (collection, api) => {
    const isVideo = collection.mimeType.includes("video");
    if (!isVideo) {
      return {
        message: "Please choose a video",
      };
    }
  };

  useEffect(() => {
    if (!videoRef.current || playVideo === null) {
      return;
    }
    playVideo ? videoRef.current.play() : videoRef.current.pause();
  }, [playVideo]);

  const { data: categoriesData } = useCategoriesQuery(client);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = props.images.indexOf(active.id as string);
      const newIndex = props.images.indexOf(over.id as string);
      const newArray = arrayMove(props.images, oldIndex, newIndex);
      props.setImages(newArray);
    }
  };

  const handleImageUpload = (
    files: OutputCollectionState<OutputCollectionStatus, "maybe-has-group">
  ) => {
    console.log(files);
    if (files.successEntries.length > 0) {
      const newImages = [];
      for (const file of files.successEntries) {
        console.log("file: ", file);
        if (file.cdnUrl) {
          newImages.push(file.cdnUrl);
        }
      }
      props.setImages([...props.images, ...newImages]);
    }

    uploaderRef.current?.api.removeAllFiles();
  };

  const handleVideoUpload = (
    files: OutputCollectionState<OutputCollectionStatus, "maybe-has-group">
  ) => {
    console.log(files);
    if (files.successEntries.length > 0) {
      const file = files.successEntries[0];
      if (file.fileInfo.cdnUrl && file.fileInfo.contentInfo?.video) {
        props.setVideoUrl(file.fileInfo.cdnUrl);
      }
    }

    videoUploaderRef.current?.api.removeAllFiles();
  };

  useEffect(() => {
    if (!categoriesData) {
      return;
    }

    if (props.categories.length === 0) {
      props.setCategories(categoriesData?.categories.map((c) => c.id) || []);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (props.ageRestrictions) {
      setEnableAgeRestrictions(true);
    }
  }, [props.ageRestrictions]);

  return (
    <ListingCard>
      <H4 margin="0 0 m">Campaign Details</H4>
      <Underline selected={true} />
      <View margin="xl 0 0" style={{ maxWidth: 520 }}>
        <Text weight="bold" margin="0">
          Campaign Name
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          Max 50 characters
        </Text>
        <Input
          inputRef={props.nameRef}
          inputSize="s"
          name="name"
          placeholder="Example campaign name..."
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
          error={props.nameError}
        />
      </View>
      <View margin="xl 0 0">
        <Flex align="center" direction="row">
          <Text weight="bold" margin="0 s 0 0">
            Campaign Details
          </Text>
          {/* <Tooltip>
            <Text margin="0">Hello</Text>
          </Tooltip> */}
        </Flex>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          Outline your campaign to help the influencer understand your goals
        </Text>
        <TextArea
          inputRef={props.detailsRef}
          name="name"
          placeholder="Describe your campaign..."
          value={props.details}
          onChange={(e) => props.setDetails(e.target.value)}
          characterLimit={500}
          characterLength={props.details.length}
          error={props.detailsError}
        />
      </View>
      <View margin="l 0 0">
        <Text weight="bold" margin="0 0 0">
          Upload Images
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          Choose 1 video and up to 5 images to showcase your campaign
        </Text>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <ImagesWrap>
            {props.videoUrl && (
              <div id={props.videoUrl} style={{ position: "relative" }}>
                <CloseWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setVideoUrl("");
                  }}
                >
                  <CloseIcon color={"#fff"} />
                </CloseWrapper>
                <ImageWrap>
                  <PlayButtonWrap
                    playVideo={playVideo}
                    onClick={() => setPlayVideo(true)}
                  >
                    <PlayIcon width={38} height={38} />
                  </PlayButtonWrap>
                  <Video
                    muted
                    ref={videoRef}
                    src={props.videoUrl}
                    onClick={() => setPlayVideo(!playVideo)}
                    onEnded={() => {
                      if (!videoRef.current) {
                        return;
                      }
                      setPlayVideo(false);
                      videoRef.current.currentTime = 0;
                    }}
                  />
                </ImageWrap>
              </div>
            )}
            <SortableContext
              items={props.images}
              strategy={rectSortingStrategy}
            >
              {props.images.map((image) => {
                return (
                  <SortableItem
                    image={image}
                    key={image}
                    setImages={props.setImages}
                    images={props.images}
                  />
                );
              })}
            </SortableContext>

            {!props.videoUrl && (
              <ImagePlaceholder
                onClick={() => videoUploaderRef.current?.api.initFlow()}
              >
                <VideoIcon width={27} colorPreset="secondary" />
                <Text size="s" margin="xs 0 0" colorPreset="secondary">
                  Choose video
                </Text>
              </ImagePlaceholder>
            )}
            {props.images.length < 5 && (
              <Flex
                direction="column"
                align="flex-start"
                style={{ overflow: "visible", width: "100%" }}
              >
                <ImagePlaceholder
                  onClick={() => uploaderRef.current?.api.initFlow()}
                  isError={!!props.imagesError}
                  ref={props.imagesRef}
                >
                  <PicturesIcon
                    colorPreset={props.imagesError ? "error" : "secondary"}
                  />
                  <Text size="s" margin="xs 0 0" colorPreset="secondary">
                    Choose images
                  </Text>
                </ImagePlaceholder>
                {props.imagesError && (
                  <Text size="xs" colorPreset="error" margin="s 0 0">
                    {props.imagesError}
                  </Text>
                )}
              </Flex>
            )}
          </ImagesWrap>
        </DndContext>
      </View>
      <View margin="xl 0 0" ref={props.categoriesRef}>
        <Text weight="bold" margin="0 0 0">
          Content Focus
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          Select the types of influencers you'd like to work with
        </Text>
        <Dropdown
          showSelectAll
          size="s"
          width={220}
          selectedOptions={props.categories}
          setSelectedOptions={(value) => props.setCategories(value)}
          renderLabel={() => {
            const totalCategories = categoriesData?.categories.length || 0;

            if (props.categories.length == 0) {
              return "Select a category";
            }

            if (props.categories.length === totalCategories) {
              return "All categories";
            }

            if (props.categories.length === 1) {
              const selectedCategory = categoriesData?.categories.find(
                (e) => e.id === props.categories[0]
              );
              return selectedCategory?.label || "";
            }

            return `${props.categories.length} categories`;
          }}
          selectionMode="multiple"
          options={
            categoriesData?.categories.map((c) => ({
              value: c.id,
              label: c.label,
            })) || []
          }
        />
        {props.categoriesError && (
          <Text size="xs" colorPreset="error" margin="s 0 0">
            {props.categoriesError}
          </Text>
        )}
      </View>
      <View margin="xl 0 0">
        <Text weight="bold" margin="0 0 0">
          Dietary Options
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          Select any dietary preferences you cater to
        </Text>
        <DietaryPreferences
          dietaryPreferences={props.dietaryPreferences}
          setDietaryPreferences={props.setDietaryPreferences}
        />
      </View>
      <View margin="xl 0 0">
        <Text weight="bold" margin="0">
          Requirements
        </Text>
        <Flex>
          <Flex
            direction="row"
            align="center"
            margin="m 0 0 0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setPhoneRequired(!props.phoneRequired);
            }}
          >
            <SingleCheckBox checked={props.phoneRequired} />
            <View margin="0 0 0 l" style={{ flex: 1 }}>
              <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
                Require phone number
              </Text>
            </View>
          </Flex>
        </Flex>
        <Flex>
          <Flex
            direction="row"
            align="center"
            margin="s 0 0 0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const newEnableAgeRestrictions = !enableAgeRestrictions;
              setEnableAgeRestrictions(newEnableAgeRestrictions);
              if (!newEnableAgeRestrictions) {
                props.setAgeRestrictions(null);
              }
            }}
          >
            <SingleCheckBox checked={enableAgeRestrictions} />
            <View margin="0 0 0 l">
              <Flex align="center">
                <Text
                  weight="semi"
                  margin="0 l 0 0"
                  style={{ userSelect: "none" }}
                >
                  Age restricted campaign
                </Text>
                <div>
                  <Dropdown
                    disableSelection={!enableAgeRestrictions}
                    size="xs"
                    selectedOptions={[props.ageRestrictions?.toString() || ""]}
                    setSelectedOptions={(value) =>
                      props.setAgeRestrictions(Number(value[0]))
                    }
                    renderLabel={() => {
                      const selectedRestriction = ageRestrictions.find(
                        (e) => e.value === props.ageRestrictions
                      );
                      return selectedRestriction
                        ? selectedRestriction.label
                        : "None";
                    }}
                    selectionMode="single"
                    options={ageRestrictions.map((restriction) => ({
                      ...restriction,
                      value: restriction.value.toString(),
                    }))}
                  />
                </div>
              </Flex>
            </View>
          </Flex>
        </Flex>
      </View>
      <StyledFileUploaderRegular
        pubkey="04ec353cf089294c8374"
        apiRef={uploaderRef}
        sourceList="local,url,facebook,instagram,gdrive,gphotos,dropbox,onedrive"
        imageShrink="800x600"
        imgOnly
        cropPreset="4:3"
        multiple={true}
        onDoneClick={handleImageUpload}
        classNameUploader="uploader"
      />
      <StyledFileUploaderRegular
        pubkey="04ec353cf089294c8374"
        apiRef={videoUploaderRef}
        sourceList="local,url,facebook,instagram,gdrive,gphotos,dropbox,onedrive"
        fileValidators={[isVideoValidator]}
        multiple={false}
        onDoneClick={handleVideoUpload}
        classNameUploader="uploader"
      />
    </ListingCard>
  );
};

export default ListingDetails;

const StyledFileUploaderRegular = styled(FileUploaderRegular)`
  .uploader {
    [uc-simple-btn] {
      display: none;
    }
  }
`;

interface DietaryPreferencesProps {
  setDietaryPreferences: React.Dispatch<
    React.SetStateAction<Listing["dietaryPreferences"]>
  >;
  dietaryPreferences: Listing["dietaryPreferences"];
}

const DietaryPreferences = (props: DietaryPreferencesProps) => {
  const client = useGqlClient();

  const { data, isLoading, error } = useDietaryPreferencesQuery(client);

  if (isLoading || !data) {
    return (
      <Flex align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  const allPreferences = data.dietaryPreferences.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <Flex wrap="wrap" margin="0 0 0">
      {allPreferences.map((p) => {
        return (
          <ToggleButton
            key={p.id}
            title={p.label}
            active={
              props.dietaryPreferences &&
              props.dietaryPreferences.length > 0 &&
              props.dietaryPreferences.includes(p.id)
                ? true
                : false
            }
            margin="0 m s 0"
            onClick={() => {
              const preferences = props.dietaryPreferences
                ? props.dietaryPreferences
                : [];

              const updatedPreferences = preferences.includes(p.id)
                ? preferences.filter((e) => e !== p.id)
                : [...preferences, p.id];

              props.setDietaryPreferences(updatedPreferences);
            }}
          />
        );
      })}
    </Flex>
  );
};

const ImagesWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 144px);
  grid-column-gap: ${(p) => p.theme.spacing.l};
  grid-row-gap: ${(p) => p.theme.spacing.m};
  margin-bottom: ${(p) => p.theme.spacing.s};
  justify-content: start;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 144px);
    grid-row-gap: ${(p) => p.theme.spacing.m};
  }
`;

interface SortableProps {
  image: string;
  setImages: (newImages: string[]) => void;
  images: string[];
}

const SortableItem = (props: SortableProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.image });

  return (
    <div
      id={props.image}
      key={props.image}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 2 : undefined,
        opacity: isDragging ? 0.3 : 1,
        position: "relative",
        cursor: "grab",
      }}
    >
      <CloseWrapper
        onClick={(e) => {
          e.stopPropagation();
          props.setImages(
            props.images.filter((img: string) => img !== props.image)
          );
        }}
      >
        <CloseIcon color={"#fff"} />
      </CloseWrapper>
      <ImageWrap key={props.image} {...attributes} {...listeners}>
        <Image src={props.image} />
      </ImageWrap>
    </div>
  );
};

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  width: 16px;
  height: 16px;
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background-color: #00000099;
  padding: 2px;
  z-index: 1;

  &:hover {
    background: #000;
  }
`;

const ImageWrap = styled.div`
  position: relative;
  width: 152px;
  object-fit: cover;
  height: 114px;
  box-sizing: border-box;
  &:hover ${CloseWrapper} {
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }
`;

const Image = styled.img`
  position: relative;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  height: 114px;
  width: 152px;
  border: 1px solid transparent;
  box-sizing: border-box;
`;

const Video = styled.video`
  position: relative;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  width: 152px;
  height: 114px;
  object-fit: cover;
  border: 1px solid transparent;
  box-sizing: border-box;
`;

const ImagePlaceholder = styled.div<{ isError?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1.5px dashed ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;

  height: 114px;
  width: 152px;

  &:hover {
    border: 1.5px dashed ${(p) => p.theme.color.typography.secondary};

    p {
      color: ${(p) => p.theme.color.typography.secondary};
    }

    svg {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  ${(p) =>
    p.isError &&
    css`
      border: 1.5px dashed ${p.theme.color.typography.error} !important;

      svg {
        fill: ${p.theme.color.typography.error} !important;
      }

      p {
        color: ${p.theme.color.typography.error} !important ;
      }
    `}
`;

const PlayButtonWrap = styled.div<{ playVideo: boolean | null }>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 150ms linear;
  border-radius: ${(p) => p.theme.misc.borderRadius};

  ${(p) =>
    p.playVideo
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `}
`;
